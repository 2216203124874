<template>
  <v-text-field
    placeholder="Type here to search for a book"
    filled
    rounded
    clearable
    dense
    v-model="searchTerm"
    color="secondary"
    @keydown.enter.stop.prevent="$event.target.blur()"
    @blur="updateSearchQuery"
  >
  </v-text-field>
</template>
<script>
import { clone } from "@/util";
export default {
  name: "BookSearch",
  data() {
    return { searchTerm: "" };
  },
  watch: {
    $route(newRoute, oldRoute) {
      if (newRoute !== oldRoute) {
        this.getSearchTerm();
      }
    },
    searchTerm(term) {
      if (!term) {
        this.updateSearchQuery();
      }
    },
  },
  mounted() {
    this.getSearchTerm();
  },
  methods: {
    getSearchTerm() {
      const searchTerm = this.$route.query.search;
      if (typeof searchTerm != "string") {
        this.searchTerm = "";
      }
      this.searchTerm = searchTerm;
    },
    updateSearchQuery() {
      let query = clone(this.$route.query);

      if (!this.searchTerm) {
        if (!query.search) {
          return;
        }

        delete query.search;
        this.$router.push({ query });
        return;
      }

      if (query.search == this.searchTerm) {
        return;
      }

      query.search = this.searchTerm;
      this.$router.push({ query });
    },
  },
};
</script>
